import React, { useCallback, useEffect, useState } from "react";
import "./webdevv.css";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { MdHomeRepairService } from "react-icons/md";
import { industries } from "../../constants/industries";
import CreatableSelect from "react-select/creatable";
import { commonRoles } from "../../constants/commonRoles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import { questnServices as services } from "../../constants/data";
import { ScrollToTop } from "../../components";
import { fetchUserData, submitServiceInterest } from "../../api";
import { onboardingOptions } from "../../constants/data";
import QImageSelector from "../../components/QnUIs/QImageSelector";
import thanks from "../../assets/images/thanks.png";
import { FaHome } from "react-icons/fa";
import { debounce } from "lodash";

const QnIntroForm = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [typeOfUser, setTypeOfUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [errors, setErrors] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isReturningUser, setIsReturningUser] = useState(false);
  const [step2IconColor, setStep2IconColor] = useState("#131010f2");
  const [showThankYou, setShowThankYou] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [showTopTitle, setShowTopTitle] = useState(true);

  const navigate = useNavigate();

  const handleServiceSelection = (service) => {
    setSelectedService(service);
    console.log("selected service: ", service);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!fullName) newErrors.fullName = "Full Name is required";
    if (!company) newErrors.company = "Company is required";
    if (!email) newErrors.email = "Email is required";
    if (!phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    if (!role) newErrors.role = "Role is required";
    if (!industry) newErrors.industry = "Industry is required";

    setErrors(newErrors);

    // If no errors, return true
    return Object.keys(newErrors).length === 0;
  };

  const handleComplete = async () => {
    if (!validateForm()) {
      alert("Please fill in all required fields.");
      return;
    }

    const formData = {
      selectedService: selectedService.title,
      fullName,
      company,
      email,
      phoneNumber: !phoneNumber.includes("+") ? "+" + phoneNumber : phoneNumber,
      role: role ? role.value : "",
      industry: industry ? industry.value : "",
    };
    console.log("Form completed!", formData);
    setLoading(true);
    try {
      const result = await submitServiceInterest(formData);
      if (result.success) {
        setLoading(false);
        setShowThankYou(true);
        setShowTopTitle(false);
      } else {
        setLoading(false);
        setShowThankYou(false);
        setErrors({ general: result.message });
      }
    } catch (error) {
      setLoading(false);
      setShowThankYou(true);
      setErrors({ general: error.message });
    }
  };

  const handleTabChange = ({ prevIndex, nextIndex }) => {
    setCurrentIndex(nextIndex - 1);
  };

  // Debounce function to fetch user data
  const debouncedFetchUserData = useCallback(
    debounce(async (email) => {
      console.log("debouncedFetchUserData called with email:", email);
      if (isReturningUser && email) {
        setEmailLoading(true);
        try {
          const userData = await fetchUserData(email);

          console.log("User data fetched:", userData);

          if (!userData.success) {
            setEmailLoading(false);
            setIsReturningUser(true);
            resetFields();
            setErrors({
              email: "Email not found. Please check and try again.",
            });
          } else if (userData.success) {
            console.log("User data fetched:", userData);
            setErrors({});
            setFullName(userData.data.name);
            setCompany(userData.data.company);
            setPhoneNumber(userData.data.phone);
            setRole({ value: userData.data.role, label: userData.data.role });
            setIndustry({
              value: userData.data.industry,
              label: userData.data.industry,
            });
            setEmailLoading(false);
            // setIsReturningUser(false); // Enable fields
          } else {
            setEmailLoading(false);
            setIsReturningUser(true);
            resetFields();
            setErrors({
              email: "Email not found. Please check and try again.",
            });
          }
        } catch (error) {
          console.log("Error fetching user data:", error);
          setEmailLoading(false);
          setIsReturningUser(true);
          resetFields();
          setErrors({
            general: "Failed to fetch user data. Please try again.",
          });
        }
      }
    }, 1000),
    [isReturningUser]
  );

  const nextTemplate = (handleNext) => (
    <div
      className="wizard-footer-right"
      style={{
        backgroundColor: "rgb(0, 0, 0)",
        borderColor: "rgb(0, 0, 0)",
        borderRadius: "4px",
      }}
    >
      <button
        className="wizard-btn"
        onClick={() => {
          if (currentIndex === 0 && !typeOfUser) {
            alert("Please select an option.");
            return;
          } else if (currentIndex === 1 && !selectedService) {
            alert("Please select a service.");
            return;
          } else if (currentIndex === 2 && !validateForm()) {
            alert("Please fill in all required fields.");
            return;
          } else {
            handleNext();
          }
        }}
      >
        Next
      </button>
    </div>
  );

  const resetFields = () => {
    setFullName("");
    setCompany("");
    setPhoneNumber("");
    setRole(null);
    setIndustry(null);
  };

  const handleEmailChange = (e) => {
    let emailValue = e.target.value;
    console.log("updated email: ", emailValue);
    setIsReturningUser(true);
    resetFields();
    setEmail(emailValue);
    debouncedFetchUserData(emailValue);
  };

  useEffect(() => {
    if (currentIndex === 0) {
      setLoading(false);
      setStep2IconColor("#131010f2");
      setErrors({});
    }

    if (currentIndex === 1) {
      setLoading(false);
      setStep2IconColor("white");
      setErrors({});
    }

    if (currentIndex === 2) {
      setStep2IconColor("#131010f2");
    }
  }, [currentIndex]);

  useEffect(() => {
    if (typeOfUser !== null) {
      if (typeOfUser.id === "new") {
        console.log("type of user: ", typeOfUser);
        setEmail("");
        resetFields();
        setIsReturningUser(false);
      } else {
        setEmail("");
        resetFields();
        setIsReturningUser(true);
      }
    }
  }, [typeOfUser]);

  return (
    <>
      <section
        id="wizard"
        className={`${
          showThankYou &&
          "h-100 d-flex justify-content-center align-items-center"
        }`}
      >
        {showTopTitle && !showThankYou && (
          <h1 className="text-center pt-6 pb-4 bg-gradient-to-r from-gray-800 via-slate-600 to-gray-700 text-2xl !font-semibold text-transparent bg-clip-text px-24">
            Let's get started with some preliminaries.
          </h1>
        )}

        <div className="wrapper">
          <div
            className={`${!showTopTitle && "pt-5"} ${
              !showThankYou && "container"
            }`}
          >
            {!showThankYou && (
              <FormWizard
                stepSize="sm"
                color="#000"
                onTabChange={handleTabChange}
                onComplete={handleComplete}
                nextButtonTemplate={nextTemplate}
              >
                <FormWizard.TabContent title="Onboarding" icon="ti-user">
                  <h2>Are You a Returning User?</h2>

                  <div
                    id="onboarding"
                    className="row gap-5 mt-5 mb-4 justify-content-center"
                  >
                    {onboardingOptions.map((option, index) => (
                      <div className="col-md-4" key={index}>
                        <div
                          className="service-box"
                          onClick={() => setTypeOfUser(option)}
                        >
                          <span className="onboarding-check-option">
                            <span>
                              <input
                                type="checkbox"
                                id={`onboarding${index}`}
                                name="onboarding"
                                checked={typeOfUser === option}
                                readOnly
                                className="custom-checkbox"
                              />
                              <label
                                htmlFor={`onboarding-checkbox-${index}`}
                              ></label>
                            </span>
                          </span>
                          <img
                            src={option.icon}
                            alt={option.title}
                            className="img-fluid"
                          />
                          <p>{option.title}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </FormWizard.TabContent>
                <FormWizard.TabContent
                  title="Service"
                  icon={<MdHomeRepairService color={step2IconColor} />}
                >
                  <h2>What Service Do You Need?</h2>
                  <p>
                    Your response will help us to understand your needs better.
                  </p>

                  <div className="row mb-5">
                    {services.map((service, index) => (
                      <div className="col-md-4" key={index}>
                        <div
                          className="service-box"
                          onClick={() => handleServiceSelection(service)}
                        >
                          <span className="service-check-option">
                            <span>
                              <input
                                type="checkbox"
                                id={`service${index}`}
                                name="service1"
                                checked={selectedService === service}
                                readOnly
                                className="custom-checkbox"
                              />
                              <label
                                htmlFor={`service-checkbox-${index}`}
                              ></label>
                            </span>
                          </span>
                          <img
                            src={service.icon}
                            alt={service.title}
                            className="img-fluid"
                          />
                          <h3>{service.title}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="services"></div>
                </FormWizard.TabContent>
                <FormWizard.TabContent
                  title="Additional Info"
                  icon="ti-settings"
                >
                  {!loading ? (
                    <div className="info-section">
                      <h2>Let's Get Some Info</h2>
                      <p>
                        Please provide us with some additional information about
                        your project.
                      </p>

                      <div className="row form-info">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center">
                              <label htmlFor="email">Email</label>
                              {typeOfUser?.id !== "new" && (
                                <small className="d-block">
                                  Please enter your email to fetch your data.
                                </small>
                              )}
                            </div>
                            <input
                              type="email"
                              className={`form-control ${
                                emailLoading ? "position-relative" : ""
                              }`}
                              id="email"
                              name="email"
                              value={email}
                              onChange={(e) => {
                                if (isReturningUser) {
                                  handleEmailChange(e);
                                } else {
                                  setEmail(e.target.value);
                                }
                              }}
                              required
                            />

                            {emailLoading && (
                              <div className="emailLoader">
                                <Spinner />
                              </div>
                            )}

                            {errors.email && (
                              <div className="error">{errors.email}</div>
                            )}
                          </div>
                          <div
                            className={`form-group ${
                              isReturningUser ? "blurred" : ""
                            }`}
                          >
                            <label htmlFor="fullname">Full Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="fullname"
                              name="fullname"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                              disabled={isReturningUser}
                              required
                            />
                            {errors.fullName && (
                              <div className="error">{errors.fullName}</div>
                            )}
                          </div>
                          <div
                            className={`form-group ${
                              isReturningUser ? "blurred" : ""
                            }`}
                          >
                            <label htmlFor="company">Company</label>
                            <input
                              type="text"
                              className="form-control"
                              id="company"
                              name="company"
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                              disabled={isReturningUser}
                              required
                            />
                            {errors.company && (
                              <div className="error">{errors.company}</div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`form-group ${
                              isReturningUser ? "blurred" : ""
                            }`}
                          >
                            <label htmlFor="phone">Phone Number</label>
                            <PhoneInput
                              country={"us"}
                              value={phoneNumber}
                              onChange={setPhoneNumber}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              inputclassName="form-control"
                              containerclassName="phone-input-container"
                              disabled={isReturningUser}
                            />
                            {errors.phoneNumber && (
                              <div className="error">{errors.phoneNumber}</div>
                            )}
                          </div>
                          <div
                            className={`form-group ${
                              isReturningUser ? "blurred" : ""
                            }`}
                          >
                            <label htmlFor="role">Your Role at</label>
                            <CreatableSelect
                              isClearable
                              placeholder="Select a Role"
                              options={commonRoles}
                              value={role}
                              onChange={setRole}
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                  width: "100%",
                                  height: "100%",
                                }),
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#d4d4d4",
                                  primary: "#000",
                                },
                              })}
                              isDisabled={isReturningUser}
                            />
                            {errors.role && (
                              <div className="error">{errors.role}</div>
                            )}
                          </div>
                          <div
                            className={`form-group ${
                              isReturningUser ? "blurred" : ""
                            }`}
                          >
                            <label htmlFor="industry">Industry</label>
                            <CreatableSelect
                              isClearable
                              options={industries}
                              placeholder="Select an Industry"
                              value={industry}
                              onChange={setIndustry}
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                  width: "100%",
                                  height: "100%",
                                }),
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#d4d4d4",
                                  primary: "#000",
                                },
                              })}
                              isDisabled={isReturningUser}
                            />
                            {errors.industry && (
                              <div className="error">{errors.industry}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      {errors.general && (
                        <div className="error">{errors.general}</div>
                      )}
                    </div>
                  ) : (
                    <div className="submission-section">
                      <h2>Processing Your Information</h2>
                      <p>
                        Within the next few seconds, we will redirect you to the
                        project specific questionnaire.
                      </p>

                      <div className="row justify-content-center ">
                        <div className="col-12">
                          <div className="text-center mb-5">
                            <Spinner />
                          </div>
                          <a className="btn-link">Click here</a> to go to the
                          questionnaire, or wait to be redirected.
                        </div>
                      </div>
                    </div>
                  )}
                </FormWizard.TabContent>
              </FormWizard>
            )}
          </div>

          {showThankYou && (
            <div
              className="row justify-content-center submitted "
              style={{
                marginTop: "-8rem",
              }}
            >
              <div className="col-md-12">
                <div className="d-flex justify-content-center ">
                  <img src={thanks} alt="Thank You" className="img-fluid" />
                </div>
                <p>
                  Your information has been submitted successfully. <br />
                  We will surely reach out to you shortly with the next steps.
                </p>
                <div className="d-flex justify-content-center ">
                  <button
                    className="btn3 d-flex align-items-center"
                    onClick={() => navigate("/")}
                  >
                    <FaHome size={20} className="pl-1 ml-2" />
                    <span className="ml-2">Go Home</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <style>{`
            @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
          `}</style>
      </section>
      <ScrollToTop />
    </>
  );
};

export default QnIntroForm;
