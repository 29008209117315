import React, { useState } from "react";
import "./q-tooltip.css";
import { IoHelpCircle } from "react-icons/io5";

const QTooltip = ({ content }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleToggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className="tooltip-container">
      <IoHelpCircle
        size={20}
        color="#6c757d"
        className="mt-1 tooltip-icon"
        onClick={handleToggleTooltip}
      />
      <div
        className={`tooltip tooltip-arrow-down ${
          showTooltip ? "tooltip-visible" : ""
        }`}
      >
        <div className="tooltip-title">Tip</div>
        <div className="tooltip-separator"></div>
        <div
          className="tooltip-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};

export default QTooltip;
