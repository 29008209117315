import React from "react";
import FormWrapper from "../../FormWrapper";
import "./step3.css";
import QTextArea from "../../QnUIs/QTextArea";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";

const GraphicStep3 = ({
  designStyle,
  similarDesigns,
  keepExistingElements,
  willProvideContent,
  updateFields,
}) => {
  return (
    <FormWrapper title="Design Preferences and Requirements">
      <div className="form-group">
        <label htmlFor={"graphic-design-style"}>
          What style or feel would you like for your design (e.g., professional,
          modern, edgy)?
        </label>
        <QTextArea
          id={"graphic-design-style"}
          className={"form-control mt-3 w-75"}
          rows={2}
          value={designStyle}
          onChange={(e) => updateFields({ designStyle: e.target.value })}
          required={true}
          placeholder={"E.g. I want a modern and professional design"}
        />
      </div>

      <div className="form-group">
        <label htmlFor={"similar-designs"}>
          Are there any existing logos, flyers, or designs you find visually
          appealing? Please provide links or descriptions.
        </label>
        <QTextArea
          id={"similar-designs"}
          className={"form-control mt-3 w-75"}
          rows={2}
          value={similarDesigns}
          onChange={(e) => updateFields({ similarDesigns: e.target.value })}
          required={true}
          placeholder={"E.g. I like the logo on www.example.com"}
        />
      </div>

      <div className="form-group">
        <label htmlFor={"keep-existing-elements"}>
          Are there any elements from your existing designs that you want to
          keep?
        </label>
        <QTextArea
          id={"keep-existing-elements"}
          className={"form-control mt-3 w-75"}
          rows={2}
          value={keepExistingElements}
          onChange={(e) =>
            updateFields({ keepExistingElements: e.target.value })
          }
          required={true}
          placeholder={"E.g. I want to keep the color scheme"}
        />
      </div>

      <div className="form-group">
        <label htmlFor={"will-provide-content"}>
          Will you provide the content (images, text), or do you need assistance
          with content creation?
        </label>
        <div className="mt-3">
          <YesNoSwitch
            id={"will-provide-content"}
            customNoLabel="No, I need assistance"
            customYesLabel="Yes, I will provide content"
            value={willProvideContent}
            onChange={(e) =>
              updateFields({ willProvideContent: e.target.value })
            }
            labelStyles={{ fontWeight: "500" }}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default GraphicStep3;
