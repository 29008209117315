import React from "react";
import FormWrapper from "../../FormWrapper";
import QTextArea from "../../QnUIs/QTextArea";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";
import QNumberOptionSelector from "../../QnUIs/QNumberSelector";

const numbers = [
  {
    label: "1-5",
    value: "1-5",
  },
  {
    label: "6-10",
    value: "6-10",
  },
  {
    label: "11-20",
    value: "11-20",
  },
  {
    label: "21-50",
    value: "21-50",
  },
  {
    label: "50+",
    value: "50+",
  },
];

const WebStep3 = ({
  similarWebsites,
  willProvideContent,
  haveGraphics,
  anticipatedNoOfPages,
  updateFields,
}) => {
  return (
    <FormWrapper title="Design and Content Requirements">
      <div className="form-group">
        <label id="similarWebsites">
          Are there any websites you find particularly user-friendly or visually
          appealing? (Share links if available)
        </label>
        <div className="mt-3">
          <QTextArea
            id={"similarWebsites"}
            value={similarWebsites}
            onChange={(e) => updateFields({ similarWebsites: e.target.value })}
            placeholder={"E.g. https://example.com, https://website.com"}
            className={"form-control w-100"}
          />
        </div>
      </div>

      <div className="form-group">
        <label>
          Will you provide the content, or do you need assistance with content
          creation?
        </label>
        <div className="mt-3">
          <YesNoSwitch
            value={willProvideContent}
            onChange={(e) =>
              updateFields({ willProvideContent: e.target.value })
            }
            customNoLabel="No, I need assistance"
            customYesLabel="Yes, I will provide"
            name="willProvideContent"
            labelStyles={{
              fontWeight: "500",
            }}
          />
        </div>
      </div>

      <div className="form-group">
        <label>
          Do you have high-quality images and graphics, or will you require
          graphic design services?
        </label>
        <div className="mt-3">
          <YesNoSwitch
            value={haveGraphics}
            onChange={(e) => updateFields({ haveGraphics: e.target.value })}
            customNoLabel="No, I need graphic design services"
            customYesLabel="Yes, I have images"
            name="haveGraphics"
            labelStyles={{
              fontWeight: "500",
            }}
          />
        </div>
      </div>

      <div className="form-group">
        <label>How many pages do you anticipate your website will have?</label>
        <QNumberOptionSelector
          numbers={numbers}
          value={anticipatedNoOfPages}
          onChange={updateFields}
        />
      </div>
    </FormWrapper>
  );
};

export default WebStep3;
