import React from "react";
import "./qtextarea.css";

const QTextArea = ({
  value,
  placeholder,
  onChange,
  name,
  id,
  rows,
  cols,
  disabled,
  required,
  className,
  style,
  ...rest
}) => {
  return (
    <textarea
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      rows={rows}
      cols={cols}
      disabled={disabled}
      required={required}
      className={`q-textarea ${className}`}
      style={style}
      {...rest}
    ></textarea>
  );
};

export default QTextArea;
