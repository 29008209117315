import React from "react";
import FormWrapper from "../../FormWrapper";
import "./step1.css";
import QTextArea from "../../QnUIs/QTextArea";
import OptionSelector from "../../QnUIs/OptionSelector";

const designServices = [
  {
    label: "Logo Design",
    value: "logoDesign",
  },
  {
    label: "Flyer Design",
    value: "flyerDesign",
  },
];

const GraphicStep1 = ({
  designType,
  targetAudience,
  otherText,
  updateFields,
}) => {
  return (
    <FormWrapper title="Graphic Design">
      <div className="form-group">
        <label>Which graphic design service are you interested in?</label>
        <div className="mt-3">
          <OptionSelector
            type={"designType"}
            options={designServices}
            value={designType}
            otherText={otherText}
            onChange={updateFields}
          />
        </div>
      </div>

      <div className="form-group">
        <label>Who is your target audience (e.g., age, interests)?</label>
        <QTextArea
          id={"graphic-target-audience"}
          className={"form-control mt-3 w-75"}
          rows={2}
          value={targetAudience}
          onChange={(e) => updateFields({ targetAudience: e.target.value })}
          required={true}
          placeholder={"E.g. 18-35, interested in technology"}
        />
      </div>
    </FormWrapper>
  );
};

export default GraphicStep1;
