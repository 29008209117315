import React, { useEffect } from "react";
import FormWrapper from "../../FormWrapper";
import QTextArea from "../../QnUIs/QTextArea";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";

const MobileStep2 = ({
  mobileType,
  businessAppPurpose,
  targetUsers,
  keyFeatures,
  requiredIntegrations,
  typeOfContent,
  sourcesOfRevenue,
  interactionFeatures,
  selfLearningOrInstructor,
  ageGroup,
  specificSubjects,
  requiredExtraFeatures,
  appFocus,
  externalIntegrations,
  extraFeatureImportance,
  appTypeVision,
  essentialFeatures,
  primaryAudience,
  updateFields,
}) => {
  const businessForm = () => {
    return (
      <FormWrapper title="Business App Specifics">
        <div className="form-group">
          <label htmlFor="businessAppPurpose">
            What is the main purpose of your business app (e.g., sales tool,
            customer service, internal management)?
          </label>
          <QTextArea
            id="businessAppPurpose"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={businessAppPurpose}
            onChange={(e) =>
              updateFields({ businessAppPurpose: e.target.value })
            }
            placeholder="E.g. To sell products, to provide services, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="targetUsers">
            Who are your target users and what are their key demographics?
          </label>
          <QTextArea
            id="targetUsers"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={targetUsers}
            onChange={(e) => updateFields({ targetUsers: e.target.value })}
            placeholder="E.g. Agency owners, employees, customers, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="keyFeatures">
            What are the key features you need (e.g., payment processing,
            bookings, notifications)?
          </label>
          <QTextArea
            id="keyFeatures"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={keyFeatures}
            onChange={(e) => updateFields({ keyFeatures: e.target.value })}
            placeholder="E.g. Payment processing, bookings, notifications, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="requiredIntegrations">
            Do you require any integrations with existing systems (e.g., CRM,
            Email Marketting, ERP)?
          </label>
          <QTextArea
            id="requiredIntegrations"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={requiredIntegrations}
            onChange={(e) =>
              updateFields({ requiredIntegrations: e.target.value })
            }
            placeholder="E.g. CRM, Email Marketting, ERP, etc."
          />
        </div>
      </FormWrapper>
    );
  };

  const entertainmentForm = () => {
    return (
      <FormWrapper title="Entertainment App Specifics">
        <div className="form-group">
          <label htmlFor="typeOfContent">
            What kind of content will your app offer (e.g., streaming, games,
            interactive stories)?
          </label>

          <QTextArea
            id="typeOfContent"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={typeOfContent}
            onChange={(e) => updateFields({ typeOfContent: e.target.value })}
            placeholder="E.g. Streaming, games, interactive stories, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="sourcesOfRevenue">
            What are the main sources of revenue for your app (e.g., premium
            content, merchandise sales)?
          </label>

          <QTextArea
            id="sourcesOfRevenue"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={sourcesOfRevenue}
            onChange={(e) => updateFields({ sourcesOfRevenue: e.target.value })}
            placeholder="E.g. Premium content, merchandise sales, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="interactionFeatures">
            What are the main interaction features for users (e.g., social
            sharing, leaderboards, in-app communication)?
          </label>
          <QTextArea
            id="interactionFeatures"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={interactionFeatures}
            onChange={(e) =>
              updateFields({ interactionFeatures: e.target.value })
            }
            placeholder="E.g. Social sharing, leaderboards, in-app communication, etc."
          />
        </div>
      </FormWrapper>
    );
  };

  const educationalForm = () => {
    return (
      <FormWrapper title="Educational App Specifics">
        <div className="form-group">
          <label htmlFor="selfLearningOrInstructor">
            Is the app intended for self-directed learning or classroom
            assistance?
          </label>
          <div className="mt-3">
            <YesNoSwitch
              id="selfLearningOrInstructor"
              customNoLabel="Self-directed learning"
              customYesLabel="Classroom assistance"
              value={selfLearningOrInstructor}
              onChange={(e) =>
                updateFields({ selfLearningOrInstructor: e.target.value })
              }
              labelStyles={{ fontWeight: "500" }}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="ageGroup">
            What age group or education level is the app designed for?
          </label>

          <QTextArea
            id="ageGroup"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={ageGroup}
            onChange={(e) => updateFields({ ageGroup: e.target.value })}
            placeholder="E.g. Elementary, High School, College, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="specificSubjects">
            Are there specific subjects or skills the app will focus on?
          </label>
          <QTextArea
            id="specificSubjects"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={specificSubjects}
            onChange={(e) => updateFields({ specificSubjects: e.target.value })}
            placeholder="E.g. Math, Science, History, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="requiredExtraFeatures">
            Do you need features like progress tracking, quizzes, or multimedia
            integration?
          </label>
          <QTextArea
            id="requiredExtraFeatures"
            className={"form-control mt-3 w-75"}
            rows={2}
            value={requiredExtraFeatures}
            onChange={(e) =>
              updateFields({ requiredExtraFeatures: e.target.value })
            }
            placeholder="E.g. Progress tracking, quizzes, multimedia integration, etc."
          />
        </div>
      </FormWrapper>
    );
  };

  const healthFitnessForm = () => {
    return (
      <FormWrapper title="Health & Fitness App Specifics">
        <div className="form-group">
          <label htmlFor="appFocus">
            Is the app focused on fitness tracking, diet management, mental
            health, or another area?
          </label>
          <QTextArea
            id="appFocus"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={appFocus}
            onChange={(e) => updateFields({ appFocus: e.target.value })}
            placeholder="E.g. Fitness tracking, diet management, mental health, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="externalIntegrations">
            What sensors or devices should the app integrate with (e.g., GPS,
            heart rate monitor)?
          </label>
          <QTextArea
            id="externalIntegrations"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={externalIntegrations}
            onChange={(e) =>
              updateFields({ externalIntegrations: e.target.value })
            }
            placeholder="E.g. GPS, heart rate monitor, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="extraFeatureImportance">
            How important are features like goal setting, notifications, or
            community challenges?
          </label>
          <QTextArea
            id="extraFeatureImportance"
            className={"form-control mt-3 w-75"}
            rows={2}
            value={extraFeatureImportance}
            onChange={(e) =>
              updateFields({ extraFeatureImportance: e.target.value })
            }
            placeholder="E.g. Goal setting, notifications, community challenges, etc."
          />
        </div>
      </FormWrapper>
    );
  };

  const otherMobileForm = () => {
    return (
      <FormWrapper title="Mobile App Specifics">
        <div className="form-group">
          <label htmlFor="appTypeVision">
            Please describe the type of app you are envisioning.
          </label>
          <QTextArea
            id="appTypeVision"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={appTypeVision}
            onChange={(e) => updateFields({ appTypeVision: e.target.value })}
            placeholder="E.g. Social media, e-commerce, cryptocurrency, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="essentialFeatures">
            What are the essential functions and features it must have?
          </label>
          <QTextArea
            id="essentialFeatures"
            className={"form-control mt-3 w-75"}
            rows={2}
            value={essentialFeatures}
            onChange={(e) =>
              updateFields({ essentialFeatures: e.target.value })
            }
            placeholder="E.g. User authentication, messaging, search, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="primaryAudience">
            Who is the primary audience for the app?
          </label>
          <QTextArea
            id="primaryAudience"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={primaryAudience}
            onChange={(e) => updateFields({ primaryAudience: e.target.value })}
            placeholder="E.g. Teenagers, young adults, professionals, etc."
          />
        </div>
      </FormWrapper>
    );
  };

  const chooseForm = () => {
    switch (mobileType) {
      case "business":
        return businessForm();
      case "entertainment":
        return entertainmentForm();
      case "educational":
        return educationalForm();
      case "healthFitness":
        return healthFitnessForm();
      default:
        return otherMobileForm();
    }
  };

  useEffect(() => {
    console.log("Mobile type: ", mobileType);
  }, [mobileType]);

  return chooseForm();
};

export default MobileStep2;
