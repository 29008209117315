import React from "react";
import FormWrapper from "../../FormWrapper";
import QTextArea from "../../QnUIs/QTextArea";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";

const MobileStep3 = ({
  designPreferences,
  typeOfUIUX,
  willProvideContent,
  similarApps,
  updateFields,
}) => {
  return (
    <FormWrapper title="Design and User Experience Requirements">
      <div className="form-group">
        <label htmlFor="designPreferences">
          Do you have any specific design preferences or existing brand
          guidelines?
        </label>
        <QTextArea
          id="designPreferences"
          value={designPreferences}
          className={"form-control mt-3 w-75"}
          rows={1}
          onChange={(e) => updateFields({ designPreferences: e.target.value })}
          required={true}
          placeholder={
            "E.g. The design should include dark mode, use our brand colors (blue and white), etc."
          }
        />
      </div>

      <div className="form-group">
        <label htmlFor="typeOfUIUX">
          What kind of user interface (UI) and user experience (UX) are you
          envisioning?
        </label>
        <QTextArea
          id="typeOfUIUX"
          value={typeOfUIUX}
          className={"form-control mt-3 w-75"}
          rows={1}
          onChange={(e) => updateFields({ typeOfUIUX: e.target.value })}
          required={true}
          placeholder={
            "E.g. Simple and clean, modern, material design, will provide wireframes, etc."
          }
        />
      </div>

      <div className="form-group">
        <label htmlFor="willProvideContent">
          Will you provide the content for the app or need help in content
          creation?
        </label>
        <div className="mt-3">
          <YesNoSwitch
            customNoLabel="No, I need help with content creation"
            customYesLabel="Yes, I will provide the content"
            value={willProvideContent}
            onChange={(e) =>
              updateFields({ willProvideContent: e.target.value })
            }
            name="willProvideContent"
            labelStyles={{ fontWeight: "500" }}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="similarApps">
          Are there existing apps that you find effective or visually appealing?
        </label>
        <QTextArea
          id="similarApps"
          value={similarApps}
          className={"form-control mt-3 w-75"}
          rows={1}
          onChange={(e) => updateFields({ similarApps: e.target.value })}
          required={true}
          placeholder={"E.g. I like the design of Instagram, Facebook, etc."}
        />
      </div>
    </FormWrapper>
  );
};

export default MobileStep3;
