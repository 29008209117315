import React, { useEffect, useState } from "react";
import "./option-selector.css";
import { FaPlus, FaMinus } from "react-icons/fa";
import QTextArea from "../QTextArea";

const OptionSelector = ({
  type,
  options,
  onChange,
  value,
  otherField,
  otherValueProp,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [otherValue, setOtherValue] = useState("");
  const [isRotated, setIsRotated] = useState(false);

  const handleOptionChange = (option, event) => {
    setSelectedOption(event.target.value);
    console.log("option clicked: ", option);
    setOtherValue(""); // Clear otherValue if option is selected other than "Other"
    setIsRotated(true);
    onChange({ [type]: event.target.value, [otherField]: "" });
  };

  const handleOtherChange = (event) => {
    const newOtherValue = event.target.value;
    console.log("newOtherValue", newOtherValue);
    setOtherValue(newOtherValue);
    onChange({ [type]: "other", [otherField]: newOtherValue });
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    if (selectedOption !== "other") {
      setOtherValue("");
    } else {
      setOtherValue(otherValueProp);
    }
  }, [selectedOption]);

  return (
    <div className="option-selector">
      {options.map((option) => (
        <div key={option.value}>
          <input
            type="radio"
            id={option.value}
            name="option-selector"
            value={option.value}
            required
            checked={selectedOption === option.value}
            onChange={(event) => handleOptionChange(option, event)}
          />
          <label
            htmlFor={option.value}
            className="d-flex justify-content-center gap-2 align-items-center "
          >
            <div
              className={`icon ${
                selectedOption === option.value && isRotated
                  ? "rotate-minus"
                  : "rotate-plus"
              }`}
            >
              {selectedOption === option.value ? <FaPlus /> : <FaMinus />}
            </div>
            <span>{option.label}</span>
          </label>
        </div>
      ))}
      <div>
        <input
          type="radio"
          id="other"
          name="option-selector"
          value="other"
          checked={selectedOption === "other"}
          onChange={(event) => handleOptionChange("other", event)}
        />
        <div className="d-flex flex-column align-items-start">
          <label
            htmlFor="other"
            className="mb-3 d-flex justify-content-center gap-2 align-items-center"
          >
            <div
              className={`icon ${
                selectedOption === "other" && isRotated
                  ? "rotate-minus"
                  : "rotate-plus"
              }`}
            >
              {selectedOption === "other" ? <FaPlus /> : <FaMinus />}
            </div>
            <span>Other</span>
          </label>

          {selectedOption === "other" && (
            <QTextArea
              value={otherValue}
              onChange={handleOtherChange}
              className="form-control"
              placeholder="Please specify..."
              required={selectedOption === "other"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OptionSelector;
