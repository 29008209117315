import React, { useEffect, useState } from "react";
import FormWrapper from "../../FormWrapper";
import QTextArea from "../../QnUIs/QTextArea";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";
import QImageSelector from "../../QnUIs/QImageSelector";
import dellImage from "../../../assets/images/Dell_Logo.png";
import googleImage from "../../../assets/images/Google_logo.png";
import bmwImage from "../../../assets/images/bmw_logo.png";

const imageOptions = [
  { value: "onColor", label: "One Color", image: dellImage },
  { value: "multipleColors", label: "Multiple Colors", image: googleImage },
  { value: "gradient", label: "Gradient", image: bmwImage },
];

const GraphicStep2 = ({
  designType,
  businessName,
  uniqueStory,
  primaryMessage,
  emphasizedWords,
  includeTagline,
  whatColors,
  whyTheColors,
  flyerTheme,
  willBePrinted,
  eventDetails,
  eventContact,
  willProvideColors,
  brandGuidelines,
  purposeOfDesign,
  specificDesignElements,
  haveHighResGraphics,
  updateFields,
}) => {
  const logoForm = () => {
    return (
      <FormWrapper title="Logo Design Specifics">
        <div className="form-group">
          <label htmlFor="businessName">
            What is the name of your business/company?
          </label>
          <QTextArea
            id="businessName"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={businessName}
            onChange={(e) => updateFields({ businessName: e.target.value })}
            placeholder="E.g. Google, Apple, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="uniqueStory">
            Is there a unique story behind your business or name?
          </label>
          <QTextArea
            id="uniqueStory"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={uniqueStory}
            onChange={(e) => updateFields({ uniqueStory: e.target.value })}
            placeholder="E.g. How did you start? What makes you unique?"
          />
        </div>

        <div className="form-group">
          <label htmlFor="primaryMessage">
            What primary message do you want to convey to your customers through
            your logo?
          </label>
          <QTextArea
            id="primaryMessage"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={primaryMessage}
            onChange={(e) => updateFields({ primaryMessage: e.target.value })}
            placeholder="E.g. Trust, Innovation, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="emphasizedWords">
            Which words in your company name do you want to emphasise in the
            logo?
          </label>
          <QTextArea
            id="emphasizedWords"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={emphasizedWords}
            onChange={(e) => updateFields({ emphasizedWords: e.target.value })}
            placeholder="E.g. Bold, Modern, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="includeTagline">
            Do you want to include a tagline into the logo?
          </label>
          <div className="mt-3">
            <YesNoSwitch
              value={includeTagline}
              onChange={(e) => updateFields({ includeTagline: e.target.value })}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="whatColors">
            What colours do you want or not want to be used in your logo and
            why?
          </label>
          <div className="mt-4">
            <QImageSelector
              field="whatColors"
              textAreaField="whyTheColors"
              options={imageOptions}
              value={whatColors}
              onChange={updateFields}
              whyValue={whyTheColors}
            />
          </div>
        </div>
      </FormWrapper>
    );
  };

  const flyerForm = () => {
    return (
      <FormWrapper title="Flyer Design Specifics">
        <div className="form-group">
          <label htmlFor="flyerTheme">
            What is the theme or concept behind your flyer project? (Event,
            product launch, etc.)
          </label>
          <QTextArea
            id="flyerTheme"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={flyerTheme}
            onChange={(e) => updateFields({ flyerTheme: e.target.value })}
            placeholder="E.g. Modern, Vintage, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="willBePrinted">
            Will the flyer be printed or used online (social media)? If printed,
            specify the size.
          </label>
          <QTextArea
            id="willBePrinted"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={willBePrinted}
            onChange={(e) => updateFields({ willBePrinted: e.target.value })}
            placeholder="E.g. 11x17 inches, A4, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="eventDetails">
            What is the event title/theme, date, and time?
          </label>
          <QTextArea
            id="eventDetails"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={eventDetails}
            onChange={(e) => updateFields({ eventDetails: e.target.value })}
            placeholder="E.g. Date, Time, Venue, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="eventContact">
            What is the contact information for the event (name, email, phone
            number)?
          </label>
          <QTextArea
            id="eventContact"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={eventContact}
            onChange={(e) => updateFields({ eventContact: e.target.value })}
            placeholder="E.g. Email, Phone, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="willProvideColors">
            What colours do you want to use, or do you have existing brand
            guidelines?
          </label>
          <div className="mt-3">
            <YesNoSwitch
              customNoLabel="No, I will provide the colours"
              customYesLabel="Yes, I have brand guidelines"
              value={willProvideColors}
              onChange={(e) =>
                updateFields({ willProvideColors: e.target.value })
              }
              labelStyles={{ fontWeight: "500" }}
            />
          </div>
        </div>
      </FormWrapper>
    );
  };

  const otherForm = () => {
    return (
      <FormWrapper title="Design Specifics">
        <div className="form-group">
          <label htmlFor="purposeOfDesign">
            Please describe the purpose of your design project.
          </label>
          <QTextArea
            id="purposeOfDesign"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={purposeOfDesign}
            onChange={(e) => updateFields({ purposeOfDesign: e.target.value })}
            placeholder="E.g. What is the goal of the design?"
          />
        </div>

        <div className="form-group">
          <label htmlFor="specificDesignElements">
            Are there any specific design elements or features you want to
            incorporate?
          </label>
          <QTextArea
            id="specificDesignElements"
            className={"form-control mt-3 w-75"}
            rows={1}
            value={specificDesignElements}
            onChange={(e) =>
              updateFields({ specificDesignElements: e.target.value })
            }
            placeholder="E.g. Specific images, text, etc."
          />
        </div>

        <div className="form-group">
          <label htmlFor="haveHighResGraphics">
            Do you have high-quality images or graphics, or do you need us to
            create them?
          </label>
          <div className="mt-3">
            <YesNoSwitch
              customNoLabel="No, I need you to create them"
              customYesLabel="Yes, I have high-quality graphics"
              value={haveHighResGraphics}
              onChange={(e) =>
                updateFields({ haveHighResGraphics: e.target.value })
              }
              labelStyles={{ fontWeight: "500" }}
            />
          </div>
        </div>
      </FormWrapper>
    );
  };

  const chooseForm = () => {
    switch (designType) {
      case "logoDesign":
        return logoForm();
      case "flyerDesign":
        return flyerForm();
      default:
        return otherForm();
    }
  };

  useEffect(() => {
    console.log("graphic type: ", designType);
  }, [designType]);

  return chooseForm();
};

export default GraphicStep2;
