import React from "react";
import FormWrapper from "../../FormWrapper";
import QTextArea from "../../QnUIs/QTextArea";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";
import QTooltip from "../../QnUIs/QTooltip";

const MobileStep4 = ({
  primaryGoalsUserEngagment,
  desiredLaunchTime,
  requireOngoingSupport,
  requireMarkettingServices,
  willManageTheApp,
  updateFields,
}) => {
  return (
    <FormWrapper title="Final Details: Scope, Timing & Support">
      <div className="form-group">
        <label htmlFor="primaryGoalsUserEngagment">
          What are your primary goals for the app in terms of user engagement
          and functionality?
        </label>
        <QTextArea
          id="primaryGoalsUserEngagment"
          value={primaryGoalsUserEngagment}
          className={"form-control mt-3 w-75"}
          rows={1}
          onChange={(e) =>
            updateFields({ primaryGoalsUserEngagment: e.target.value })
          }
          required={true}
          placeholder={
            "E.g. We want an app where users can easily book appointments, view our services, contact support easily etc."
          }
        />
      </div>

      <div className="form-group">
        <label htmlFor="desiredLaunchTime">
          What is your desired timeline for launching the app?
        </label>
        <QTextArea
          id="desiredLaunchTime"
          value={desiredLaunchTime}
          className={"form-control mt-3 w-75"}
          rows={1}
          onChange={(e) => updateFields({ desiredLaunchTime: e.target.value })}
          required={true}
          placeholder={"E.g. Earlier the better, but within 6 months"}
        />
      </div>

      <div className="form-group">
        <label htmlFor="requireOngoingSupport">
          Would you like ongoing support and maintenance after the app's launch?
        </label>
        <div className="mt-3">
          <YesNoSwitch
            value={requireOngoingSupport}
            onChange={(e) =>
              updateFields({ requireOngoingSupport: e.target.value })
            }
            customNoLabel="No, I will manage it myself"
            customYesLabel="Yes, I would like you to manage it"
            name="requireOngoingSupport"
            labelStyles={{
              fontWeight: "500",
            }}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="d-flex align-items-center">
          <label htmlFor="requireMarkettingServices">
            Do you require any marketing services to promote the app?
          </label>
          <div className="ml-2">
            <QTooltip content="This could include services like Google Ads, Facebook Ads, Social Media Marketing etc." />
          </div>
        </div>
        <div className="mt-3">
          <YesNoSwitch
            value={requireMarkettingServices}
            onChange={(e) =>
              updateFields({ requireMarkettingServices: e.target.value })
            }
            customNoLabel="No, I will market it myself"
            customYesLabel="Yes, I would like you to manage it"
            name="requireMarkettingServices"
            labelStyles={{
              fontWeight: "500",
            }}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="d-flex align-items-center">
          <label htmlFor="willManageTheApp">
            Would you prefer to manage the app content and update yourself, or
            would you like us to handle it?
          </label>
          <div className="ml-2">
            <QTooltip
              content={
                "Choosing 'Yes' means we'll provide a <b>'Dashboard'</b> for you to manage the app content and updates yourself. Choosing 'No' means we'll manage the app content and updates for you."
              }
            />
          </div>
        </div>
        <div className="mt-3">
          <YesNoSwitch
            value={willManageTheApp}
            onChange={(e) => updateFields({ willManageTheApp: e.target.value })}
            customNoLabel="No, I want you to manage it"
            customYesLabel="Yes, I want to manage it myself"
            name="willManageTheApp"
            labelStyles={{
              fontWeight: "500",
            }}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default MobileStep4;
