import React from "react";
import "./form-wrapper.css";

const FormWrapper = ({ title, id, children }) => {
  return (
    <div id={id} className="form-wrapper">
      <div className="title-container">
        <h2 style={{ margin: 0, marginBottom: "2rem" }}>{title}</h2>

        <hr className="line" />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default FormWrapper;
