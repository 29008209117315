import { SERVER_URL } from './utils/environ';

export const submitServiceInterest = async (formData) => {
    try {
        console.log("outgoing form data: ", formData)
        const response = await fetch(`${SERVER_URL}/service-interest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error submitting form: ", error);
        throw new Error("An error occurred. Please try again.");
    }
};

export const fetchUserData = async (email) => {
    try {
        const response = await fetch(`${SERVER_URL}/users?email=${email}`);
        const result = await response.json();

        console.log("response 1: ", result);
        if (!result.success) {
            // check status code
            if (response.status === 303) {
                return result;
            }
        }
        return result;
    } catch (error) {
        console.error("Error fetching user data: ", error);
        // return {
        //     error
        // }
        throw new Error(error.message);
    }
};

export const submitQuestionnaire = async (email, qid, token, formData) => {
    try {
        const response = await fetch(`${SERVER_URL}/questionnaire/submit/${email}/${qid}/${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error submitting questionnaire: ", error);
        throw new Error("An error occurred. Please try again.");
    }
};

export const verifyToken = async (email, qid, token) => {
    try {
        const response = await fetch(`${SERVER_URL}/questionnaire/verify/${email}/${qid}/${token}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error verifying token: ", error);
        throw new Error("An error occurred. Please try again.");
    }
};