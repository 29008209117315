import React from "react";
import "./qn-loader.css";
import qLogo from "../../../assets/images/q-white-boldless.png";

const QnLoader = () => {
  return (
    <div className="loader">
      <div className="svg-wrapper">
        <img src={qLogo} className="qlogo" alt="loading qlogo" />
        {/* <div className="loading-text">Loading...</div> */}
      </div>
    </div>
  );
};

export default QnLoader;
