import React, { useState } from "react";
import FormWrapper from "../../FormWrapper";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";
import OptionSelector from "../../QnUIs/OptionSelector";

const services = [
  {
    label: "Portfolio",
    value: "portfolio",
  },
  {
    label: "E-commerce",
    value: "eCommerce",
  },
  {
    label: "Social Network",
    value: "socialNetwork",
  },
];

const WebStep1 = ({ cms, websiteType, updateFields, otherText }) => {
  const [cmsEnabled, setCmsEnabled] = useState(false);

  return (
    <FormWrapper title="Website Service">
      <div className="form-group">
        <label>
          Would you like your website to be powered by a Content Management
          System (CMS) such as WordPress?
        </label>
        <div className="mt-3">
          <YesNoSwitch
            value={cms}
            onChange={(e) => updateFields({ cms: e.target.value })}
            name="cms"
          />
        </div>
      </div>

      <div className="form-group">
        <label>What type of website are you interested in?</label>
        <div className="mt-3">
          <OptionSelector
            type={"websiteType"}
            options={services}
            otherField={"otherText"}
            value={websiteType}
            otherValueProp={otherText}
            onChange={updateFields}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default WebStep1;
