import React, { useEffect, useState } from "react";
import "./yesnoswitch.css";

const YesNoSwitch = ({
  value,
  onChange,
  customYesLabel = "YES",
  customNoLabel = "NO",
  name = "yes-no-switch",
  labelStyles,
}) => {
  const [uniqueId] = useState(() => Math.random().toString(36).substr(2, 9));
  const yesId = `yes-${name}-${uniqueId}`;
  const noId = `no-${name}-${uniqueId}`;

  const yesValue = customYesLabel.toLowerCase();
  const noValue = customNoLabel.toLowerCase();

  return (
    <div className="yes-no-switch">
      <input
        type="radio"
        id={yesId}
        name={name}
        value={yesValue}
        checked={value === yesValue}
        onChange={onChange}
        required
      />
      <label htmlFor={yesId} style={labelStyles || {}}>
        {customYesLabel}
      </label>
      <input
        type="radio"
        id={noId}
        name={name}
        value={noValue}
        checked={value === noValue}
        onChange={onChange}
        required
      />
      <label htmlFor={noId} style={labelStyles || {}}>
        {customNoLabel}
      </label>
    </div>
  );
};

export default YesNoSwitch;
