import React, { useEffect, useState } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import questnlogo from "../../assets/images/Questn black.png";
import GraphicStep1 from "../../components/QnForms/GraphicStep1";
import GraphicStep2 from "../../components/QnForms/GraphicStep2";
import GraphicStep3 from "../../components/QnForms/GraphicStep3";
import WebStep1 from "../../components/QnForms/WebStep1";
import WebStep2 from "../../components/QnForms/WebStep2";
import WebStep3 from "../../components/QnForms/WebStep3";
import WebStep4 from "../../components/QnForms/WebStep4";
import { questnServices } from "../../constants/data";
import { useMultistepForm } from "../../hooks/useMultistepForm";
import "./steps.css";
import "./qn.css";
import { GraphicSteps, WebSteps, MobileSteps } from "./stepsList";
import GraphicStep4 from "../../components/QnForms/GraphicStep4";
import MobileStep1 from "../../components/QnForms/MobileStep1";
import MobileStep2 from "../../components/QnForms/MobileStep2";
import MobileStep3 from "../../components/QnForms/MobileStep3";
import MobileStep4 from "../../components/QnForms/MobileStep4";
import { submitQuestionnaire, verifyToken } from "../../api";
import { SessionExpired, Spinner } from "../../components";

const GRAPHIC_DATA = {
  designType: "",
  targetAudience: "",
  otherText: "",
  // logo specific fields
  businessName: "",
  uniqueStory: "",
  primaryMessage: "",
  emphasizedWords: "",
  includeTagline: "",
  whatColors: "",
  whyTheColors: "",
  // flyer specific fields
  flyerTheme: "",
  willBePrinted: "",
  eventDetails: "",
  eventContact: "",
  willProvideColors: "",
  brandGuidelines: "",
  // other specific fields
  purposeOfDesign: "",
  specificDesignElements: "",
  haveHighResGraphics: "",
  // step 3
  designStyle: "",
  similarDesigns: "",
  keepExistingElements: "",
  willProvideContent: "",
  // step 4
  designBudget: "",
  projectCompletion: "",
  ongoingMaintenanceOrFullControl: "",
  anythingElse: "",
};

const WEB_DATA = {
  cms: "",
  websiteType: "",
  otherText: "",
  serviceDesc: "",
  targetAudience: "",
  displayPreferences: "",
  includeTestimonials: "",
  productDesc: "",
  ecomFeatures: "",
  integratePlatforms: "",
  brandGuidelines: "",
  centralTheme: "",
  userInteractions: "",
  privacySecurity: "",
  socialNetworkInspiration: "",
  selectedPages: [],

  similarWebsites: "",
  willProvideContent: "",
  haveGraphics: "",
  anticipatedNoOfPages: "",

  idealLaunchDate: "",
  wouldLikeToManageContent: "",
  wantFullControl: "",
  anythingElse: "",
  interestedInWordPressTraining: "",
  requiresCustomDashboard: "",
};

const MOBILE_DATA = {
  mobileType: "",
  platform: "",
  otherMobileText: "",
  // if business
  businessAppPurpose: "",
  targetUsers: "",
  keyFeatures: "",
  requiredIntegrations: "",
  // if entertainment
  typeOfContent: "",
  sourcesOfRevenue: "",
  interactionFeatures: "",
  // if education
  selfLearningOrInstructor: "",
  ageGroup: "",
  specificSubjects: "",
  requiredExtraFeatures: "",
  // if health
  appFocus: "",
  externalIntegrations: "",
  extraFeatureImportance: "",
  // if other
  appTypeVision: "",
  essentialFeatures: "",
  primaryAudience: "",
  // step 3
  designPreferences: "",
  typeOfUIUX: "",
  willProvideContent: "",
  similarApps: "",
  // step 4
  primaryGoalsUserEngagment: "",
  desiredLaunchTime: "",
  requireOngoingSupport: "",
  requireMarkettingServices: "",
  willManageTheApp: "",
};

const Questionnaire = () => {
  const { qid, email, token } = useParams();
  const navigate = useNavigate();

  const [formSteps, setFormSteps] = useState([]);
  const [progressSteps, setProgressSteps] = useState([]);
  const [selectedService, setSelectedService] = useState("");

  const [graData, setGraData] = useState(GRAPHIC_DATA);
  const [webData, setWebData] = useState(WEB_DATA);
  const [mobileData, setMobileData] = useState(MOBILE_DATA);

  const [isTokenValid, setIsTokenValid] = useState(null);

  const updateGraFields = (fields) => {
    setGraData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const updateWebFields = (fields) => {
    setWebData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const updateMobileFields = (fields) => {
    setMobileData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    totalCompletedSteps,
    back,
    next,
  } = useMultistepForm(formSteps);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted");
    if (!isLastStep) return next();

    let formData;
    let serviceType;

    if (selectedService === "Graphic Design") {
      formData = graData;
      serviceType = "Graphic Design";
    } else if (selectedService === "Website Development") {
      formData = webData;
      serviceType = "Website Development";
    } else if (selectedService === "Mobile App Development") {
      formData = mobileData;
      serviceType = "Mobile App Development";
    }

    console.log("Form Data: ", {
      serviceType,
      formData,
    });

    try {
      const response = await submitQuestionnaire(email, qid, token, formData);

      if (response.success) {
        navigate("/qn/thankyou");
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error submitting questionnaire: ", error);
      alert("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      try {
        const result = await verifyToken(email, qid, token);
        setIsTokenValid(result.success);
      } catch (error) {
        setIsTokenValid(false);
      }
    };

    checkToken();
  }, [email, qid, token]);

  useEffect(() => {
    console.log("Graphic Data", graData);
  }, [graData]);

  useEffect(() => {
    console.log("Web Data", webData);
  }, [webData]);

  useEffect(() => {
    console.log("qId: ", qid);

    // get the corresponding service from the qId
    const service = questnServices.find((service) => service.id === qid);

    if (service) {
      setSelectedService(service.title);
      switch (service.title) {
        case "Graphic Design":
          setProgressSteps(GraphicSteps);
          setFormSteps([
            <GraphicStep1 {...graData} updateFields={updateGraFields} />,
            <GraphicStep2 {...graData} updateFields={updateGraFields} />,
            <GraphicStep3 {...graData} updateFields={updateGraFields} />,
            <GraphicStep4 {...graData} updateFields={updateGraFields} />,
          ]);
          break;
        case "Website Development":
          setProgressSteps(WebSteps);
          setFormSteps([
            <WebStep1 {...webData} updateFields={updateWebFields} />,
            <WebStep2 {...webData} updateFields={updateWebFields} />,
            <WebStep3 {...webData} updateFields={updateWebFields} />,
            <WebStep4 {...webData} updateFields={updateWebFields} />,
          ]);
          break;
        case "Mobile App Development":
          setProgressSteps(MobileSteps);
          setFormSteps([
            <MobileStep1 {...mobileData} updateFields={updateMobileFields} />,
            <MobileStep2 {...mobileData} updateFields={updateMobileFields} />,
            <MobileStep3 {...mobileData} updateFields={updateMobileFields} />,
            <MobileStep4 {...mobileData} updateFields={updateMobileFields} />,
          ]);
          break;
        default:
          break;
      }
    }
  }, [qid, graData, webData, mobileData]);

  if (isTokenValid === null) {
    return <Spinner />;
  }

  if (!isTokenValid) {
    return <SessionExpired />;
  }

  return (
    <div id="qn" className="">
      <div className="row">
        <div className="qn-sidebar col-">
          {/* steps header title with company name */}
          <div className="container pt-3">
            <div className=" pt-4 pb-3">
              {/* logo */}
              <img
                src={questnlogo}
                alt="questn logo"
                className="img-fluid logo"
              />

              <div className="mt-4 mr-4">
                <h2>Tell us about your project</h2>
                <p className="mt-2 mb-0">
                  This will help us understand your project better so we can
                  provide you with a more accurate proposal.
                </p>
              </div>
            </div>

            {/* multisteps form progress */}
            <div className="steps">
              <ul className="progress">
                {progressSteps.map((step, index) => (
                  <li
                    key={index}
                    className={`progress-item ${
                      currentStepIndex >= index && "is-done"
                    }`}
                  >
                    <span>{step.no}</span>
                    <div className="text">
                      <strong>{step.title}</strong>
                      <p>{step.desc}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <hr className="line" />
        </div>
        <div className="form-step pr-5">
          <div className="py-5">
            <form onSubmit={handleSubmit}>
              {step}

              <div className="button-container d-flex align-items-center justify-content-between mt-4">
                {!isFirstStep && (
                  <button type="button" className="btn navbtn" onClick={back}>
                    <span className="d-flex align-items-center gap-1 ">
                      <IoArrowBack /> <span>Back</span>
                    </span>
                  </button>
                )}
                <button type="submit" className="btn navbtn">
                  {isLastStep ? (
                    "Finish"
                  ) : (
                    <span className="d-flex align-items-center gap-1 justify-content-center ">
                      Next <IoArrowForward />
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
