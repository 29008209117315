import React from "react";
import "./notfound.css";

const NotFound = () => {
  return (
    <>
      <div>NotFound</div>
    </>
  );
};

export default NotFound;
