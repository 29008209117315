import React, { useEffect, useState } from "react";
import "./image-selector.css";
import QTextArea from "../QTextArea";

const QImageSelector = ({
  options,
  onChange,
  value,
  field,
  textAreaField,
  whyValue,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [reason, setReason] = useState("");

  const handleOptionChange = (option) => {
    setSelectedOption(option.value);
    setReason("");
    console.log({ [field]: option.value, [textAreaField]: "" });
    onChange({ [field]: option.value, [textAreaField]: "" });
  };

  const handleReasonChange = (event) => {
    const newReason = event.target.value;
    setReason(newReason);
    onChange({ [textAreaField]: newReason });
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    if (selectedOption === value) {
      setReason(whyValue);
    } else {
      setReason("");
    }
  }, [selectedOption]);

  return (
    <>
      <div className="image-selector row">
        {options.map((option) => (
          <div key={option.value} className="col-md-4 ">
            <div
              className={`image-option ${
                selectedOption === option.value ? "selected" : ""
              }`}
              onClick={() => handleOptionChange(option)}
            >
              <span className="service-check-option">
                <span>
                  <input
                    type="checkbox"
                    id={`service-${option.value}`}
                    name={"option.value"}
                    checked={selectedOption === option.value}
                    readOnly
                    className="custom-checkbox"
                  />
                  <label htmlFor={`service-checkbox-${option.value}`}></label>
                </span>
              </span>
              <img
                src={option.image}
                alt={option.label}
                className="option-image"
              />
              <span className="option-label mt-3">{option.label}</span>
            </div>
          </div>
        ))}
      </div>

      {/* check if textAreaField is different from "" before check selectedOption */}
      {textAreaField !== "none" && selectedOption && (
        <QTextArea
          id="whyTheColors"
          className={"form-control mt-3 w-75"}
          rows={1}
          value={reason}
          onChange={handleReasonChange}
          placeholder="Please provide a reason"
          required={textAreaField !== "none"}
        />
      )}
    </>
  );
};

export default QImageSelector;
