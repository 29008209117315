import icon1 from "../assets/images/icons/icon1.png";
import icon2 from "../assets/images/icons/icon2.png";
import icon3 from "../assets/images/icons/icon3.png";
import newuser from "../assets/images/newuser.png";
import returning from "../assets/images/returning.png"

export const questnServices = [
    {
        title: "Graphic Design",
        icon: icon1,
        id: "g2r8a3c013-4icd0de986-46dc-S17ng-5060bf26",
    },
    {
        title: "Website Development",
        icon: icon2,
        id: "w5e3b8afc90-de4f10d48c-4531-8e5nt-4f1825c3",
    },
    {
        title: "Mobile App Development",
        icon: icon3,
        id: "m6o1b0f7446-a9p8c23729-4e08-de4mn-4a3d216e",
    },
];

export const onboardingOptions = [
    {
        title: "I am new here",
        id: "new",
        icon: newuser
    },
    {
        title: "I am a returning user",
        id: "returning",
        icon: returning
    }
]