import React from "react";
import FormWrapper from "../../FormWrapper";
import OptionSelector from "../../QnUIs/OptionSelector";
import QImageSelector from "../../QnUIs/QImageSelector";
import ios from "../../../assets/images/ios.png";
import android from "../../../assets/images/android.png";
import both from "../../../assets/images/ios-android.png";
import "./step1.css";

const services = [
  {
    label: "Business",
    value: "business",
  },
  {
    label: "Entertainment",
    value: "entertainment",
  },
  {
    label: "Educational",
    value: "educational",
  },
  {
    label: "Health & Fitness",
    value: "healthFitness",
  },
];

const imageOptions = [
  { value: "ios", label: "iOS", image: ios },
  { value: "android", label: "Android", image: android },
  { value: "both", label: "Both", image: both },
];

const MobileStep1 = ({
  mobileType,
  platform,
  otherMobileText,
  updateFields,
}) => {
  return (
    <FormWrapper id="mobile" title="Mobile App Service">
      <div className="form-group">
        <label htmlFor="mobileType">
          What type of mobile app are you looking to develop?
        </label>
        <div className="mt-3">
          <OptionSelector
            type={"mobileType"}
            otherField={"otherMobileText"}
            options={services}
            value={mobileType}
            onChange={updateFields}
            otherValueProp={otherMobileText}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="platform">
          Do you need the app to be developed for:
        </label>
        <div className="mt-3">
          <QImageSelector
            field="platform"
            options={imageOptions}
            value={platform}
            onChange={updateFields}
            textAreaField={"none"}
            whyValue={""}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default MobileStep1;
