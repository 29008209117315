import { Outlet, createBrowserRouter } from "react-router-dom";
import { Layout } from "../Layouts/Layout";
import QLayout from "../Layouts/QLayout";
import { Home, NotFound, QnIntroForm, Questionnaire, ThankYouPage } from "../pages/index";

const QWrapper = () => {
    return (
        <QLayout>
            <Outlet />
        </QLayout>
    );
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                index: true,
                exact: true,
                element: <Home />
            },
            {
                path: '*',
                element: <NotFound />
            }
        ]
    },
    {
        path: "/qn",
        element: <QWrapper />,
        children: [
            {
                path: "",
                element: <QnIntroForm />
            },
            {
                path: ":email/:qid/:token",
                element: <Questionnaire />
            },
            {
                path: "thankyou",
                element: <ThankYouPage />
            },
            {
                path: "*",
                element: <NotFound />
            }
        ]
    }
]);

export default router;