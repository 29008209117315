import React from "react";
import { ScrollToTop } from "../../components";
import { AuroraBackground } from "../../components/ui/AuroraBg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaLocationArrow } from "react-icons/fa6";

const Home = () => {
  return (
    <>
      <section className="bg-[#0a0723]">
        <AuroraBackground>
          <motion.div
            initial={{ opacity: 0.0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="relative flex flex-col gap-4 gap-y-8 items-center justify-center px-4"
          >
            <div className="text-3xl md:text-7xl font-bold dark:text-white text-center px-5">
              Welcome to Questn UI Kit 🚀
            </div>
            <div className="font-extralight text-base md:text-4xl dark:text-neutral-200 py-4">
              A simple and easy to use questionnaire UI kit for your project.
            </div>

            <Link
              to={"/qn"}
              className="gs inline-flex h-16 px-12  animate-shimmer items-center justify-center rounded-md border border-gra-400 bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] font-medium text-slate-300 transition-transform transition-colors focus:outline-none focus:ring-2 text-xl focus:ring-gray-800 focus:ring-offset-2 focus:ring-offset-slate-200 hover:bg-lime-800 hover:scale-95 hover:shadow-lg dark:hover:bg-blue-600 dark:hover:text-gray-300 dark:bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] dark:bg-[length:200%_100%]"
            >
              Get Started <FaLocationArrow className="ml-2" />
            </Link>

            <div className="py-5 text-gray-300">
              Created by{" "}
              <Link
                to="https://github.com/DanielGraham123"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 dark:text-blue-300"
              >
                @DanielGraham123
              </Link>
            </div>
          </motion.div>
        </AuroraBackground>
      </section>

      <ScrollToTop />
    </>
  );
};

export default Home;
