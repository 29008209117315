export const WebSteps = [
    {
        no: 1,
        title: "General Details and Type of Website",
        desc: "Let's get some background details the website you need.",
    },
    {
        no: 2,
        title: "Specifics Based on Website Type",
        desc: "We need some more details based on the type of website you need.",
    },
    {
        no: 3,
        title: "Design and Content Requirements",
        desc: "Tell us about the content you need on your website.",
    },
    {
        no: 4,
        title: "Final Details",
        desc: "Let's wrap up with some final details.",
    }
]

export const GraphicSteps = [
    {
        no: 1,
        title: "General Details and Service Type",
        desc: "Let's get some background details on the design you need.",
    },
    {
        no: 2,
        title: "Specific Questions Based on Service",
        desc: "What about the specifics of the design you need?",
    },
    {
        no: 3,
        title: "Design Preferences and Requirements",
        desc: "Share with us your design tastes and requirements.",
    },
    {
        no: 4,
        title: "Final Details",
        desc: "Let's get some final details to wrap up.",
    }
]

export const MobileSteps = [
    {
        no: 1,
        title: "General Details and App Type",
        desc: "Let's get some background details on the app you need.",
    },
    {
        no: 2,
        title: "Specifics Based on App Type",
        desc: "We need some more details based on the type of app you need.",
    },
    {
        no: 3,
        title: "Design and Feel of the App",
        desc: "Tell us about how you want your app to look and feel.",
    },
    {
        no: 4,
        title: "Final Details",
        desc: "Let's wrap up with some final details.",
    }
]