import React from "react";
import "./session-expired.css";

const SessionExpired = () => {
  return (
    <div className="d-flex align-items-center flex-column my-auto h-100 justify-content-center ">
      <h1>Session Expired</h1>
      <p>
        Your session has expired. Please contact support for further assistance.
      </p>
    </div>
  );
};

export default SessionExpired;
