import { Outlet } from "react-router-dom";
import { Footer, Navbar } from "../components/index";
import "./qnL.css";
import { ThemeProvider } from "../context/theme-provider";

export const Layout = () => {
  return (
    <>
      <ThemeProvider defaultTheme="dark" storageKey="questn-ui-theme">
        <Outlet />
        {/* <Footer /> */}
      </ThemeProvider>

      <style>
        {`
          body {
            background: var(--black-color);
          }
        `}
      </style>
    </>
  );
};
