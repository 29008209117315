import React, { useEffect } from "react";
import FormWrapper from "../../FormWrapper";
import QTextArea from "../../QnUIs/QTextArea";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";
import { IoHelpCircle } from "react-icons/io5";
import QTooltip from "../../QnUIs/QTooltip";
import OptionSelector from "../../QnUIs/OptionSelector";
import MultiOptionSelector from "../../QnUIs/MultiOptionSelector";

const websitePageTypes = [
  {
    label: "Home",
    value: "home",
  },
  {
    label: "About",
    value: "about",
  },
  {
    label: "Services",
    value: "services",
  },
  {
    label: "Contact",
    value: "contact",
  },
  {
    label: "Gallery",
    value: "gallery",
  },
  {
    label: "Blog",
    value: "blog",
  },
  {
    label: "Shop",
    value: "shop",
  },
];

const WebStep2 = ({
  serviceDesc,
  targetAudience,
  displayPreferences,
  includeTestimonials,
  websiteType,
  updateFields,
  productDesc,
  ecomFeatures,
  integratePlatforms,
  brandGuidelines,
  centralTheme,
  userInteractions,
  privacySecurity,
  socialNetworkInspiration,
  selectedPages,
  otherPagesText,
}) => {
  const portfolioForm = () => {
    return (
      <FormWrapper title="Portfolio Website Specifics">
        <div className="form-group">
          <label htmlFor="service-desc">
            Briefly describe your work or the services you provide.
          </label>
          <QTextArea
            id={"service-desc"}
            className={"form-control mt-3 w-75"}
            rows={1}
            value={serviceDesc}
            onChange={(e) => updateFields({ serviceDesc: e.target.value })}
            required={true}
            placeholder={"E.g. We are a digital marketing agency..."}
          />
        </div>

        <div className="form-group">
          <label htmlFor="target-audience">
            Who is your target audience (e.g., age, interests)?
          </label>
          <QTextArea
            id={"target-audience"}
            className={"form-control mt-3 w-75"}
            rows={1}
            value={targetAudience}
            onChange={(e) => updateFields({ targetAudience: e.target.value })}
            required={true}
            placeholder={"E.g. Our target audience is young professionals..."}
          />
        </div>

        <div className="form-group">
          <label htmlFor="display-preferences">
            How do you prefer to display your projects (e.g., image gallery,
            case studies)?
          </label>
          <QTextArea
            id={"display-preferences"}
            className={"form-control mt-3 w-75"}
            rows={1}
            value={displayPreferences}
            onChange={(e) =>
              updateFields({ displayPreferences: e.target.value })
            }
            required={true}
            placeholder={"E.g. We prefer a minimalist design..."}
          />
        </div>

        <div className="form-group mt-0">
          <label htmlFor="include-testimonials">
            Do you want to include a section for testimonials or client reviews?
          </label>
          <div className="mt-3">
            <YesNoSwitch
              value={includeTestimonials}
              onChange={(e) =>
                updateFields({ includeTestimonials: e.target.value })
              }
            />
          </div>
        </div>
      </FormWrapper>
    );
  };

  const eCommerceForm = () => {
    return (
      <>
        <FormWrapper title="E-Commerce Specifics">
          <div className="form-group">
            <label htmlFor="product-desc">
              Briefly describe the products you sell (e.g., clothing,
              electronics, digital downloads).
            </label>
            <QTextArea
              id={"product-desc"}
              className={"form-control mt-3 w-75"}
              rows={1}
              value={productDesc}
              onChange={(e) => updateFields({ productDesc: e.target.value })}
              required={true}
              placeholder={"E.g. We sell handmade jewelry..."}
            />
          </div>

          <div className="form-group">
            <label htmlFor="ecom-features">
              What essential e-commerce features do you want on your website
              (e.g., shopping cart, product filtering, customer reviews)?
            </label>
            <QTextArea
              id={"ecom-features"}
              className={"form-control mt-3 w-75"}
              rows={1}
              value={ecomFeatures}
              onChange={(e) => updateFields({ ecomFeatures: e.target.value })}
              required={true}
              placeholder={
                "E.g. We want a shopping cart, product reviews and ratings..."
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="integrate-platforms">
              Do you want to integrate your website with any other platforms
              (e.g., accounting software, shipping services)?
            </label>
            <QTextArea
              id={"integrate-platforms"}
              className={"form-control mt-3 w-75"}
              rows={1}
              value={integratePlatforms}
              onChange={(e) =>
                updateFields({ integratePlatforms: e.target.value })
              }
              required={true}
              placeholder={
                "E.g. We want to integrate with PayPal and Amazon..."
              }
            />
            <p className="note">
              You can respond with "N/A" if not applicable.
            </p>
          </div>

          <div className="form-group mt-0">
            <div className="d-flex align-items-center">
              <label htmlFor="brand-guide" className="mb-0">
                Do you have any existing brand guidelines or color preferences?
              </label>
              <div className="ml-2">
                <QTooltip content="This could include your brand colors, fonts, and logo." />
              </div>
            </div>
            <QTextArea
              id={"brand-guide"}
              className={"form-control mt-3 w-75"}
              rows={1}
              value={brandGuidelines}
              onChange={(e) =>
                updateFields({ brandGuidelines: e.target.value })
              }
              required={true}
              placeholder={"E.g. Our brand colors are blue and white..."}
            />
          </div>
        </FormWrapper>
        <style>
          {`
            // .qbg {
            //   height: 105vh;
            // }
            #qn {
              height: 105vh;
            }
            #qn .line {
              height: 105%;
            }
          `}
        </style>
      </>
    );
  };

  const socialNetworkForm = () => {
    return (
      <FormWrapper title="Social Network Specifics">
        <div className="form-group">
          <label htmlFor="central-theme">
            What is the central theme or purpose of your social network site?
          </label>
          <QTextArea
            id={"central-theme"}
            className={"form-control mt-3 w-75"}
            rows={1}
            value={centralTheme}
            onChange={(e) => updateFields({ centralTheme: e.target.value })}
            required={true}
            placeholder={
              "E.g. Our social network is for connecting students..."
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="user-interactions">
            Describe the key functionalities you want for user interaction
            (e.g., profiles, messaging, forums).
          </label>
          <QTextArea
            id={"user-interactions"}
            className={"form-control mt-3 w-75"}
            rows={1}
            value={userInteractions}
            onChange={(e) => updateFields({ userInteractions: e.target.value })}
            required={true}
            placeholder={
              "E.g. We want user profiles, messaging, and a forum..."
            }
          />
        </div>

        <div className="form-group">
          <div className="d-flex align-items-center">
            <label htmlFor="privacy-security" className="mb-0">
              How important are privacy and data security features for your
              platform?
            </label>
            <div className="ml-2">
              <QTooltip content="This could include user data protection, multi-factor authentication, legal compliance, or other security measures." />
            </div>
          </div>
          <QTextArea
            id={"privacy-security"}
            className={"form-control mt-3 w-75"}
            rows={1}
            value={privacySecurity}
            onChange={(e) => updateFields({ privacySecurity: e.target.value })}
            required={true}
            placeholder={"E.g. Privacy and data security are top priorities..."}
          />
        </div>

        <div className="form-group mt-0">
          <label htmlFor="social-network-inspiration">
            Are there any social networks that inspire your vision, and what
            elements do you wish to incorporate?{" "}
          </label>

          <QTextArea
            id={"social-network-inspiration"}
            className={"form-control mt-3 w-75"}
            rows={1}
            value={socialNetworkInspiration}
            onChange={(e) =>
              updateFields({ socialNetworkInspiration: e.target.value })
            }
            required={true}
            placeholder={
              "E.g. We like to have the chat box feature on Facebook..."
            }
          />
        </div>
      </FormWrapper>
    );
  };

  const otherWebsiteForm = () => {
    return (
      <>
        <FormWrapper title="Website Specifics">
          <div className="form-group">
            <label htmlFor="main-purpose">
              Please describe the main purpose and functionality of your desired
              website.
            </label>
            <QTextArea
              id={"main-purpose"}
              className={"form-control mt-3 w-75"}
              rows={1}
              value={centralTheme}
              onChange={(e) => updateFields({ centralTheme: e.target.value })}
              required={true}
              placeholder={
                "E.g. The website is for showcasing our products and services..."
              }
            />
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="target-audience">
                Who is your target audience (e.g., age, interests)?
              </label>
              <QTextArea
                id={"target-audience"}
                className={"form-control mt-3 w-75"}
                rows={2}
                value={targetAudience}
                onChange={(e) =>
                  updateFields({ targetAudience: e.target.value })
                }
                required={true}
                placeholder={
                  "E.g. Our target audience is young professionals..."
                }
              />
            </div>

            <div className="form-group col-md-6">
              {/* <div className="d-flex align-items-center"> */}
              <label
                htmlFor="specific-features"
                className="mb-0 d-flex align-items-center"
              >
                <span>Any features or third-party integrations you need?</span>
                <span className="ml-2">
                  <QTooltip content="This could include social media integration, payment gateways, live chat, etc." />
                </span>
              </label>
              {/* </div> */}
              <QTextArea
                id={"specific-features"}
                className={"form-control mt-3 w-75"}
                rows={2}
                value={privacySecurity}
                onChange={(e) =>
                  updateFields({ privacySecurity: e.target.value })
                }
                required={true}
                placeholder={
                  "E.g. We need a live chat feature and Instagram feed..."
                }
              />
            </div>
          </div>

          <div className="form-group mt-0">
            <label htmlFor="brand-guidelines">
              Do you have any design preferences or existing brand guidelines?
            </label>

            <QTextArea
              id={"brand-guidelines"}
              className={"form-control mt-3 w-75"}
              rows={1}
              value={brandGuidelines}
              onChange={(e) =>
                updateFields({ brandGuidelines: e.target.value })
              }
              required={true}
              placeholder={
                "E.g. We prefer a clean and modern design with blue accents..."
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="type-of-pages">
              At minimum, what types of pages would you like as part of your
              website. Check all that apply.
            </label>
            <MultiOptionSelector
              options={websitePageTypes}
              onChange={updateFields}
              value={selectedPages}
              otherPagesText={otherPagesText}
            />
          </div>
        </FormWrapper>

        <style>
          {`
            // .qbg {
            //     height: 120vh;
            // }
            // #qn {
            //   height: 120vh;
            // }
            .tooltip {
              left: -43px;
            }
            .tooltip.tooltip-arrow-down::after {
              left: 65%;
            }
        `}
        </style>
      </>
    );
  };

  const chooseForm = () => {
    switch (websiteType) {
      case "portfolio":
        return portfolioForm();
      case "eCommerce":
        return eCommerceForm();
      case "socialNetwork":
        return socialNetworkForm();
      default:
        return otherWebsiteForm();
    }
  };

  useEffect(() => {
    console.log("Web type: ", websiteType);
  }, [websiteType]);

  return chooseForm();
};

export default WebStep2;
