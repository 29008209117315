import React, { useRef, useEffect, useState } from "react";
import Confetti from "react-confetti";
import Lottie from "react-lottie-player";
import handshake from "./handshake.json";

const ThankYouPage = () => {
  const [windowDimension, setWindowDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [opacity, setOpacity] = useState(1);
  const [showReturn, setShowReturn] = useState(false);

  const detectSize = () => {
    setWindowDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => window.removeEventListener("resize", detectSize);
  }, [windowDimension]);

  useEffect(() => {
    const fadeOut = () => {
      let op = 1; // initial opacity
      const timer = setInterval(() => {
        if (op == 0.2) {
          op -= 0.1;
          setOpacity(op); // Make sure it sets to 0 at the end
        } else if (op <= 0.1) {
          clearInterval(timer);
          setOpacity(0);
          setShowReturn(true);
        } else {
          op -= 0.2;
          setOpacity(op);
        }
      }, 2000); // decrease opacity every 250ms
      return () => clearInterval(timer);
    };

    // Start fading out after 3 seconds
    const timeout = setTimeout(fadeOut, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
      <Confetti
        width={windowDimension.width}
        height={windowDimension.height}
        style={{
          opacity: opacity,
          transition: "all 1.5s linear 1s",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        <Lottie
          animationData={handshake}
          play
          style={{ width: 100, height: 100, display: "block", margin: "auto" }}
          loop={false}
        />
        <div>
          Thank you for your time.
          <br />
          We'll be in touch shortly.
          {/* click to return to the home page */}
          {showReturn && (
            <div
              style={{
                marginTop: "20px",
                fontSize: "15px",
                textDecoration: "underline",
              }}
            >
              <a href="/">Click here to return to the home page</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
