import React from "react";
import FormWrapper from "../../FormWrapper";
import QTextArea from "../../QnUIs/QTextArea";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";

const GraphicStep4 = ({
  designBuget,
  projectCompletion,
  ongoingMaintenanceOrFullControl,
  anythingElse,
  updateFields,
}) => {
  return (
    <FormWrapper title="Final Details: Budget, Timing & Support">
      <div className="form-group">
        <label htmlFor="designBuget">
          What is your budget for the design project?
        </label>
        <QTextArea
          id="designBuget"
          className={"form-control mt-3 w-75"}
          rows={1}
          value={designBuget}
          onChange={(e) => updateFields({ designBuget: e.target.value })}
          placeholder="E.g. I am looking to spend $500 - $1000"
        />
      </div>

      <div className="form-group">
        <label htmlFor="projectCompletion">
          When would you like this project to be completed?
        </label>
        <QTextArea
          id="projectCompletion"
          className={"form-control mt-3 w-75"}
          rows={1}
          value={projectCompletion}
          onChange={(e) => updateFields({ projectCompletion: e.target.value })}
          placeholder="E.g. 01/01/2022"
        />
      </div>

      <div className="form-group">
        <label htmlFor="ongoingMaintenanceOrFullControl">
          Would you like ongoing management of your design assets, or would you
          prefer full control after delivery?
        </label>
        <div className="mt-3">
          <YesNoSwitch
            value={ongoingMaintenanceOrFullControl}
            onChange={(e) =>
              updateFields({ ongoingMaintenanceOrFullControl: e.target.value })
            }
            customNoLabel="No, I would like full control"
            customYesLabel="Yes, I would like ongoing maintenance"
            name="ongoingMaintenanceOrFullControl"
            labelStyles={{ fontWeight: "500" }}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="anythingElse">
          Is there anything else you'd like us to know about your vision for
          this design project?
        </label>
        <QTextArea
          id="anythingElse"
          className={"form-control mt-3 w-75"}
          rows={2}
          value={anythingElse}
          onChange={(e) => updateFields({ anythingElse: e.target.value })}
          placeholder="E.g. I would like to add a last-minute request and call-to-action."
        />
      </div>
    </FormWrapper>
  );
};

export default GraphicStep4;
