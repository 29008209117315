import React, { useEffect, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import "./multi-option-selector.css";
import QTextArea from "../QTextArea";

const MultiOptionSelector = ({ options, onChange, value, otherPagesText }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherValue, setOtherValue] = useState("");

  const handleOptionChange = (event) => {
    const value = event.target.value;
    const isSelected = selectedOptions.includes(value);

    setSelectedOptions((prevOptions) =>
      isSelected
        ? prevOptions.filter((option) => option !== value)
        : [...prevOptions, value]
    );

    onChange({
      selectedPages: isSelected
        ? selectedOptions.filter((option) => option !== value)
        : [...selectedOptions, value],
    });

    if (value !== "other") {
      setOtherValue("");
    }
  };

  const handleOtherChange = (event) => {
    setOtherValue(event.target.value);
    if (selectedOptions.includes("other")) {
      onChange({
        otherPagesText: event.target.value,
        selectedPages: selectedOptions,
      });
    }
    // onChange({
    //   otherPagesText: event.target.value,
    //   selectedPages: selectedOptions,
    // });
  };

  useEffect(() => {
    setSelectedOptions(value);
    console.log("selected values", value);
  }, [value]);

  useEffect(() => {
    if (selectedOptions.includes("other")) {
      console.log("otherPagesText", otherPagesText);
      setOtherValue(otherPagesText);
    } else {
      setOtherValue("");
    }
  }, [selectedOptions]);

  return (
    <div className="m-option-selector">
      <div className="row gap">
        {options.map((option) => (
          <div key={option.value} className="col-md-3 mb-2">
            <div
              className={`option-item  ${
                selectedOptions.includes(option.value) && "active"
              }`}
            >
              <input
                type="checkbox"
                id={option.value}
                name="m-option-selector"
                value={option.value}
                checked={selectedOptions.includes(option.value)}
                onChange={handleOptionChange}
              />
              <label htmlFor={option.value} className="w-100">
                {option.label}
              </label>
            </div>
          </div>
        ))}
      </div>

      <div className="row mt-3">
        <div className="col-md-3">
          <div
            className={`option-item  ${
              selectedOptions.includes("other") && "active"
            }`}
          >
            <input
              type="checkbox"
              id="other"
              name="m-option-selector"
              value="other"
              checked={selectedOptions.includes("other")}
              onChange={handleOptionChange}
            />
            <label htmlFor="other" className=" ml-2">
              Other
            </label>
          </div>
        </div>
        <div className="mt-2">
          {selectedOptions.includes("other") && (
            <QTextArea
              value={otherValue}
              onChange={handleOtherChange}
              className="form-control"
              required={selectedOptions.includes("other")}
              placeholder="Please list the other pages separated by commas..."
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiOptionSelector;
