import React, { useEffect, useState } from "react";
import "./number-option-selector.css";

const QNumberOptionSelector = ({ numbers, onChange, value }) => {
  const [selectedNumber, setSelectedNumber] = useState();

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedNumber(value);
    onChange({ anticipatedNoOfPages: value });
  };

  useEffect(() => {
    setSelectedNumber(value);
  }, [value]);

  return (
    <div className="mt-3 number-group">
      {numbers.map((option, index) => (
        // <div className="">
        <label
          key={index}
          htmlFor={`optionNo${index}`}
          className={`number-box ${
            selectedNumber === option.value ? "numselected" : ""
          }`}
          onClick={handleOptionChange}
        >
          {selectedNumber === option.value && (
            <div className="check-icon"></div>
          )}
          <input
            type="radio"
            id={`optionNo${index}`}
            name="optionNo"
            value={option.value}
            // checked={selectedNumber === option.value}
            readOnly
            className="custom-checkbox visuallyhidden"
          />
          <span className="number-wrap">
            <span className="number-text">{option.label}</span>
          </span>
        </label>
        // </div>
      ))}
    </div>
  );
};

export default QNumberOptionSelector;
