import React, { useEffect } from "react";
import FormWrapper from "../../FormWrapper";
import QTextArea from "../../QnUIs/QTextArea";
import YesNoSwitch from "../../QnUIs/YesNoSwitch";

const WebStep4 = ({
  cms,
  updateFields,
  idealLaunchDate,
  wouldLikeToManageContent,
  wantFullControl,
  anythingElse,
  interestedInWordPressTraining,
  requiresCustomDashboard,
}) => {
  useEffect(() => {
    console.log("website type", cms);
  }, [cms]);

  return (
    <FormWrapper title={"Final Details: Scope, Timing & Support"}>
      <div className="form-group">
        <label>Ideally, when would you like to launch your website?</label>
        <div className="mt-3">
          <QTextArea
            id={"launchDate"}
            value={idealLaunchDate}
            onChange={(e) => updateFields({ idealLaunchDate: e.target.value })}
            placeholder={"E.g. 01/01/2022"}
            className={"form-control w-100"}
            rows={1}
            required={true}
          />
        </div>
      </div>

      <div className="form-group">
        <label>
          Would you like us to manage the content updates on your website, or
          would you prefer to manage it yourself?
        </label>
        <div className="mt-3">
          <YesNoSwitch
            value={wouldLikeToManageContent}
            onChange={(e) =>
              updateFields({ wouldLikeToManageContent: e.target.value })
            }
            customNoLabel="No, I will manage it myself"
            customYesLabel="Yes, I would like you to manage it"
            name="wouldLikeToManageContent"
            labelStyles={{
              fontWeight: "500",
            }}
          />
        </div>
      </div>

      <div className="form-group">
        <label>
          After your website is built, do you want full control handed over to
          you, including administrative access and ownership of all accounts and
          hosting?
        </label>
        <div className="mt-3">
          <YesNoSwitch
            value={wantFullControl}
            onChange={(e) => updateFields({ wantFullControl: e.target.value })}
            customNoLabel="No, I want you to maintain control"
            customYesLabel="Yes, I want full control"
            name="wantFullControl"
            labelStyles={{
              fontWeight: "500",
            }}
          />
        </div>
      </div>

      {cms === "yes" ? (
        <div className="form-group">
          <label>
            Are you interested in training or tutorials on how to use WordPress
            to manage your site independently?
          </label>
          <div className="my-3">
            <YesNoSwitch
              value={interestedInWordPressTraining}
              onChange={(e) =>
                updateFields({ interestedInWordPressTraining: e.target.value })
              }
              name="interestedInWordPressTraining"
              labelStyles={{
                fontWeight: "500",
              }}
            />
          </div>
        </div>
      ) : (
        <div className="form-group">
          <label>
            Would you require a custom-built content management system or
            dashboard to manage your website’s content?
          </label>
          <div className="my-3">
            <YesNoSwitch
              value={requiresCustomDashboard}
              onChange={(e) =>
                updateFields({ requiresCustomDashboard: e.target.value })
              }
              customNoLabel="No, I don't need a custom dashboard"
              customYesLabel="Yes, I need a custom dashboard"
              name="requiresCustomDashboard"
              labelStyles={{
                fontWeight: "500",
              }}
            />
          </div>
        </div>
      )}

      <div className="form-group">
        <label>
          Is there anything else you would like to tell us about your vision for
          your website?
        </label>
        <div className="mt-3">
          <QTextArea
            id={"anythingElse"}
            value={anythingElse}
            onChange={(e) => updateFields({ anythingElse: e.target.value })}
            placeholder={"E.g. I want some animations on the homepage"}
            className={"form-control w-100"}
            rows={2}
            required={false}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default WebStep4;
