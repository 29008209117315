// Desc: Common roles for the user to select from when filling out the form
export const commonRoles = [
    { value: "Administrative Officer", label: "Administrative Officer" },
    { value: "Communications Manager", label: "Communications Manager" },
    { value: "Executive Director", label: "Executive Director" },
    { value: "Finance Officer", label: "Finance Officer" },
    { value: "Legal Advisor", label: "Legal Advisor" },
    { value: "Marketing Manager", label: "Marketing Manager" },
    { value: "Outreach Officer", label: "Outreach Officer" },
    { value: "Program Coordinator", label: "Program Coordinator" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Social Worker", label: "Social Worker" },
];